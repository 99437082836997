import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import animated from "animate.css"; // npm install animate.css --save安装，在引入
import "babel-polyfill";
import "es6-promise/auto";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import BaiduMap from "vue-baidu-map";
import VueLazyLoad from "vue-lazyload";
import "amfe-flexible";

import ElementLocale from "element-ui/lib/locale";
import z_ch from "element-ui/lib/locale/lang/zh-CN";
import en from "element-ui/lib/locale/lang/en";
import z_chMy from "@/assets/i18n/zh.js";
import enMy from "@/assets/i18n/en.js";
import AOS from "aos";
import "aos/dist/aos.css";
Vue.use(AOS);
AOS.init();
Vue.use(VueLazyLoad, {
  loading: require("@/assets/images/product/R-C.gif"), //此图片路径为懒加载的默认图，小伙伴根据实际情况更改路径
});
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "mFZtd2yfjApgSqVvnW2EByIEb2rSfGia",
});
Vue.use(ElementUI);
Vue.use(animated);
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: store.state.language,
  messages: {
    zh: { ...z_ch, ...z_chMy },
    // z_chMy,
    en: {
      ...en,
      ...enMy,
    },
    // enMy,
  },
});
Vue.config.productionTip = false;

ElementLocale.i18n((key, value) => i18n.t(key, value));
import "@/assets/css/rest.css";
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
