<template>
  <!-- <div class="top animate__animated animate__backInLeft"> -->
  <div class="topBox">
    <div class="topBox_left">
      <div class="imgBox">
        <img src="@/assets/images/header/TLDlogo.png" alt="" />
      </div>
      <!-- <div class="routeBox">
        <router-link to="/home">{{ $t("navbar.home") }}</router-link>
      </div> -->
      <div class="routeBox">
        <router-link to="/company">{{ $t("navbar.company") }}</router-link>
      </div>
      <div class="routeBox">
        <router-link to="/product">{{ $t("navbar.product") }}</router-link>
      </div>
      <div class="routeBox">
        <router-link to="/superiority">{{
          $t("navbar.superiority")
        }}</router-link>
      </div>
      <div class="routeBox">
        <router-link to="/contact">{{ $t("navbar.contactUs") }}</router-link>
      </div>
    </div>
    <div class="topBox_right">
      <div class="rightBox_left" @click="changeLang">
        {{ $t("language.name") }}
      </div>
      <div class="rightBox_right" @click="otherUrl">
        {{ $t("navbar.backManagement") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "CompanynetworkPHeader",

  data() {
    return {};
  },
  created() {
    // var lang = navigator.language || navigator.userLanguage; //常规浏览器语言和IE浏览器
    // lang = lang.substr(0, 2); //截取lang前2位字符
    // if (lang == "zh") {
    //   localStorage.setItem("lang", "zh");
    // } else {
    //   localStorage.setItem("lang", "en");
    // }
  },

  methods: {
    ...mapMutations(["updataLanguage"]),
    changeLang(e) {
      var lang = "";
      if (e.target.innerText == "中文") {
        lang = "zh";
      } else if (e.target.innerText == "English") {
        lang = "en";
      }
      this.updataLanguage(lang);
      this.$i18n.locale = lang;
    },
    otherUrl() {
      window.open("https://iscm.cnfzflw.com", "_blank");
    },
  },
  beforeUpdate() {
    if (localStorage.getItem("lang") == "zh") {
      document.title = this.$route.meta.title;
    } else if (localStorage.getItem("lang") == "en") {
      document.title = this.$route.meta.titleEn;
    }
  },
  props: ["current"],
};
</script>

<style lang="less" scoped>
.topBox {
  // max-width: 1920px;
  // width: 1920px;
  width: 100%;
  box-sizing: border-box;
  height: 152px;
  background: #fff;
  padding: 0 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 1500px;
  .topBox_left {
    display: flex;
    align-items: center;
    .imgBox {
      margin-right: 143px;
      img {
        width: 156px;
        height: 56px;
      }
    }
    .routeBox {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #535d6f;
      margin-right: 52px;
    }
  }
  .topBox_right {
    display: flex;
    height: 64px;
    cursor: pointer;
    .rightBox_left {
      height: 64px;
      line-height: 64px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
    }
    .rightBox_right {
      margin-left: 32px;
      height: 64px;
      padding: 0 20px;
      line-height: 64px;
      background: #2e82ff;
      border-radius: 32px;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
    }
  }
  .active {
    color: #2e82ff;
  }
}
</style>