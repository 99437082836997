// en.js
module.exports = {
  language: {
    name: "中文",
    current: "Current Language",
  },
  navbar: {
    home: "Home",
    company: "Company  ",
    product: "Product",
    superiority: "Advantages",
    contactUs: "Contact us",
    backManagement: " Enter the management background",
  },
  // 产品列表
  product: {
    productClassification: "Product Classification",
    List: "Enter all lists",
  },
  // 产品分类
  // productClass: [
  //   "Category",
  //   "Material",
  //   "Function",
  //   "Applicable purpose",
  //   "Style",
  // ],
  productClass: ["Category", "Material", "Style"],
  // 产品列表页的数据
  productList: {
    product: "Product",
    productList: "List of products",
    screening_conditions: "Screening conditions",
    category: "Category",
    material_quality: "Material quality",
    Subdivision_classification: "Subdivision classification",
    Applicable_use: "Applicable use",
    style: "Style",
    resetting: "Resetting",
    Reset_all_screens: "Reset all screens",
    Figure_search_search: "Image search",
    Re_box_selection: "Re box selection",
    Change_a: "Change a",
    Cancel_the_map_search: "Cancel the map search",
  },
  company: {
    company_introduction: "Company introduction",
    company_introduce: `Jiashan road industry and trade co., LTD. Professional manufacturing buttons, ribbon, clothing accessories, luggage accessories, crafts, etc., is the national button standardization technical committee member unit, China daily goods industry association director unit, China industrial design association member units, Zhejiang creative design association, Jiaxing creative design association unit, Jiaxing industrial design association, Jiaxing civil entrepreneurs association executive vice President unit, jiashan arts and crafts industry association vice President unit, jiashan county electronic commerce association vice President unit, jiashan button chamber of commerce executive vice President unit. Intellectual property rights include: 5 authorized invention patents, 21 practical novel patents and 132 appearance design patents. The company has passed the ISO9001 quality management system certification, ISO14001 environmental management system certification, ISO45001 occupational health and safety management system certification, STANDARD 100 by OEKO-TEX international certification and product label Zhejiang manufacturing certification. Company won the: "national high-tech enterprise", "Zhejiang province innovative demonstration enterprise", "Jiaxing integrity law-abiding enterprise", "Jiaxing integrity private enterprise", "Jiaxing fine management demonstration enterprise", "Jiaxing patent demonstration enterprise", "Jiaxing growth enterprise", "jiashan civilized unit", was awarded "Jiaxing municipal industrial design center", "county button technology research and development center", "jiashan county highly skilled personnel innovation studio", etc.`,
    glories_of_company: "Glories of company",
    enterprise_qualification: "Enterprise qualification",
  },
  contact: {
    contact_us: "Contact us",
    contact_number: "Contact number",
    mail_box: "Mail box",
    Factory_address: "Factory address",
    Factory_address1:
      "#76-80 Shangwang Rd. Dashun Pioneer Park, Jiashan, Zhejiang",
    Company_address: "Company address",
    Company_address1: "T2-1302 EFC, 1118 Xiangwang Rd. Hangzhou, Zhejiang",
    Wechat: "Wechat",
  },
  // 优势与服务
  superiority: {
    enterprise_advantage: "Enterprise advantage",
    Our_service: "Our service",
    first: "Multi-category one-stop auxiliary materials efficient supply",
    second: "Industry-leading product development and quality management level",
    third:
      "Customer demand-oriented, an efficient way to solve customer problems, and grow together with customers",
    fourth:
      "Introduce the most advanced technology to provide convenient services for customers",
    Our_first: "1. Exclusive service personnel",
    Our_second: "2. Digital order tracking system",
    Our_third: "3. Perfect cloud on the exhibition hall",
    Our_fourth:
      "4. Global logistics customs declaration and settlement services",
    Our_fifth: "5. Cooperative innovation and research and development",
  },
};
