import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/index.vue";
Vue.use(VueRouter);
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  { path: "/", redirect: "/company" },
  {
    path: "/home",
    name: "Home",
    meta: { title: "首页", titleEn: "Home", keepAlive: false },
    component: Home,
  },
  {
    path: "/product",
    name: "Product",
    meta: {
      title: "产品",
      titleEn: "Product",
      keepAlive: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/product/index.vue"),
  },
  {
    path: "/product/productList",
    name: "productList",
    meta: { title: "产品列表", titleEn: "List of products", keepAlive: true },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/product/prouductList.vue"
      ),
  },
  {
    path: "/product/detail/:uid?",
    name: "ProductDetail",
    meta: { title: "产品详情", titleEn: "Product Details", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/product/detaile.vue"),
  },
  {
    path: "/company",
    name: "Company",
    meta: { title: "公司介绍", titleEn: "Company ", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/company/company.vue"),
  },
  {
    path: "/superiority",
    name: "Superiority",
    meta: { title: "优势与服务", titleEn: "Advantages ", keepAlive: false },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/superiority/superiority.vue"
      ),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: { title: "联系我们", titleEn: "Contact us", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/contact/index.vue"),
  },

  { path: "*", redirect: "/home" },
];

const router = new VueRouter({
  linkActiveClass: "active",
  routes,
});

// 路由的前置守卫
router.beforeEach((to, from, next) => {
  // console.log(localStorage.getItem("lang"));
  // to 要去哪个路由
  // form 从哪个路由来
  // 如何控制路由的跳转与取消跳转  跳转 next()  不跳转next（false） 永远不能跳转了  next() 可以重新定向到某个页面
  if (localStorage.getItem("lang") == "zh") {
    document.title = to.meta.title;
  } else if (localStorage.getItem("lang") == "en") {
    document.title = to.meta.titleEn;
  }
  // chrome
  document.body.scrollTop = 0;
  // firefox;
  document.documentElement.scrollTop = 0;
  // safari
  // window.pageYOffset = 0;
  // 判断当前页面是否需要刷新

  next();
});
export default router;
