<template>
  <div id="app">
    <headers class="headerBox"></headers>

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
 <script>
import headers from "@/components/header.vue";
export default {
  components: {
    headers,
  },
  created() {
    // 网站跳转
    this.goOrther();
  },
  methods: {
    goOrther() {
      var is_mobi =
        navigator.userAgent
          .toLowerCase()
          .match(
            /(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i
          ) != null;
      if (is_mobi) {
        // 如果是移动端就跳转到移动端地址
        let params = new URL(window.location.href).hash; // new URL 可以得到相关的地址详情
        // let baseurl = "http://localhost:8080/" + params; // 测试接口
        let baseurl = "http://m.td-button.com/" + params;
        //  利用方法跳转到对应的页面
        window.location.href = baseurl;
      } else {
        // 如果是pc 端就不用跳转到其他页面
        // window.location.href = "http://172.20.10.3:8081/"
      }
    },
  },
};
</script>

<style lang="less">
#app {
  box-sizing: border-box;
  min-width: 1610px;
  margin: 0 auto;
  max-width: 1920px;
  // margin-top: 152px;
  .headerBox {
    // position: fixed;
    // top: 0;
    // z-index: 9999;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
