import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: localStorage.getItem("lang") || "en",
  },
  mutations: {
    updataLanguage(state, lang) {
      localStorage.setItem("lang", lang);
      state.language = lang;
    },
  },
  actions: {},
  modules: {},
});
